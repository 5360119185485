import { memo, useEffect, useMemo, useState } from "react";
import { useStateRef } from "powerhooks/useStateRef";
import { useRoute } from "../router";
import { FourOhFour } from "../pages/FourOhFour";
import type { HeaderOptions } from "gitlanding/GlTemplate";
import { GlTemplate } from "gitlanding/GlTemplate";
import { GlFooter } from "gitlanding/GlFooter";
import { useSplashScreen } from "onyxia-ui";
import { Home } from "../pages/Home/Home";
import { Documentation } from "../pages/Documentation";
import { AppHeader } from "./AppHeader";
import { id } from "tsafe/id";
import { tss, useHeaderHeight } from "../theme";
import { useDomRect } from "powerhooks/useDomRect";
import { ThemeContext } from "@emotion/react";

/* spell-checker: disable */
export const App = memo(() => {
    const route = useRoute();
    const documentationStickyHeaderRef = useStateRef<HTMLDivElement>(null);
    const { setHeaderHeight } = useHeaderHeight();

    const {
        ref: headerRef,
        domRect: { height: headerHeight },
    } = useDomRect();

    useEffect(() => {
        if (headerHeight === 0) {
            return;
        }

        setHeaderHeight(headerHeight);
    }, [headerHeight]);

    {
        const { hideRootSplashScreen } = useSplashScreen();

        useEffect(
            () => {
                hideRootSplashScreen();
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [],
        );
    }

    const [isHeaderRetracted, setIsHeaderRetracted] = useState(false);

    const [pageNode, headerOptions] = useMemo(() => {
        {
            const Page = Home;

            if (Page.routeGroup.has(route)) {
                return [<Page />, Page.headerOptions] as const;
            }
        }

        {
            const Page = Documentation;

            if (Page.routeGroup.has(route)) {
                return [
                    documentationStickyHeaderRef.current !== null && (
                        <Page
                            stickyPageHeader={documentationStickyHeaderRef.current}
                            setIsHeaderRetracted={setIsHeaderRetracted}
                            route={route}
                        />
                    ),
                    Page.headerOptions,
                ] as const;
            }
        }

        return [
            <FourOhFour />,
            id<HeaderOptions>({
                "position": "sticky",
                "isRetracted": false,
            }),
        ] as const;
    }, [route, documentationStickyHeaderRef.current]);

    const { classes, theme } = useStyles();

    return (
        <GlTemplate
            classes={{
                "headerWrapper": classes.header,
            }}
            header={
                <div ref={headerRef}>
                    <AppHeader isRetracted={isHeaderRetracted} />
                    <div ref={documentationStickyHeaderRef}></div>
                </div>
            }
            headerOptions={{
                ...headerOptions,
            }}
            footer={
                <GlFooter
                    classes={{
                        "icon": classes.logo,
                        "bottomDiv": classes.footerBottomDiv,
                        "root": classes.footerRoot,
                    }}
                    iconLinks={[
                        {
                            "iconUrl": theme.isDarkModeEnabled
                                ? "https://datalab.dive.edito.eu/custom-resources/logos/Full_EU_DTO_Banner_Negative.png"
                                : "https://datalab.dive.edito.eu/custom-resources/logos/Full_EU_DTO_Banner.jpeg",
                            "href": "https://research-and-innovation.ec.europa.eu/funding/funding-opportunities/funding-programmes-and-open-calls/horizon-europe/eu-missions-horizon-europe/restore-our-ocean-and-waters/european-digital-twin-ocean-european-dto_en",
                        },
                    ]}
                />
            }
            body={pageNode}
        />
    );
});

const useStyles = tss.create(({ theme }) => ({
    "header": {
        "zIndex": 4000,
        "position": "fixed",
    },
    "logo": {
        "width": "100%",
        "borderRadius": "3px",
    },
    "footerBottomDiv": {},
    "footerRoot": {
        "height": "100%",
        "marginTop": theme.spacing(4),
        "paddingTop": theme.spacing(2),
    },
}));
