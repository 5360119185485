import type { LocalizedString as GenericLocalizedString } from "i18nifty";

//List the languages you with to support
export const languages = ["en", "fr", "it", "es", "de"] as const;

//If the user's browser language doesn't match any
//of the languages above specify the language to fallback to:

export type Language = (typeof languages)[number];
export type Languages = Language[];

export const fallbackLanguage: Language = "en";

export type LocalizedString = GenericLocalizedString<Language>;
