import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { declareComponentKeys } from "i18nifty";
import { useTranslation } from "../../i18n/index";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { tss } from "../../theme";

function createData(
    feature: tableTranslationKeys,
    canExplore: boolean,
    canCreate: boolean,
    canContribute: boolean,
) {
    return { feature, canExplore, canCreate, canContribute };
}

const rows = [
    createData("feature1", true, true, true),
    createData("feature2", true, true, true),
    createData("feature3", true, true, true),
    createData("feature4", true, true, true),
    createData("feature5", false, true, true),
    createData("feature6", false, true, true),
    createData("feature7", false, true, true),
    createData("feature8", false, true, true),
    createData("feature9", false, false, true),
    createData("feature10", false, false, true),
    createData("feature11", false, false, true),
    createData("feature12", false, false, true),
    createData("feature13", false, false, true),
    createData("feature14", false, false, true),
    createData("feature15", false, false, true),
    createData("feature16", false, false, true),
    createData("feature17", false, false, true),
];

export default function BetaCapabilitiesTable() {
    const { t } = useTranslation({ BetaCapabilitiesTable });
    const { classes } = useStyles();
    return (
        <div className={classes.root}>
            <TableContainer className={classes.table} component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <div className={classes.title}>{t("features")}</div>
                            </TableCell>
                            <TableCell className={classes.cell} align="center">
                                <div className={classes.title}>{t("explore1")}</div>
                                <div>{t("explore2")}</div>
                                <div>{t("explore3")}</div>
                            </TableCell>
                            <TableCell className={classes.cell} align="center">
                                <div className={classes.title}>{t("create1")}</div>
                                <div>{t("create2")}</div>
                                <div>{t("create3")}</div>
                            </TableCell>
                            <TableCell className={classes.cell} align="center">
                                <div className={classes.title}>{t("contribute1")}</div>
                                <div>{t("contribute2")}</div>
                                <div>{t("contribute3")}</div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (
                            <TableRow key={row.feature}>
                                <TableCell
                                    className={classes.cell}
                                    component="th"
                                    scope="row"
                                >
                                    {t(row.feature)}
                                </TableCell>
                                <TableCell className={classes.cell} align="center">
                                    {row.canExplore && <TaskAltIcon color="success" />}
                                </TableCell>
                                <TableCell className={classes.cell} align="center">
                                    {row.canCreate && <TaskAltIcon color="success" />}
                                </TableCell>
                                <TableCell className={classes.cell} align="center">
                                    {row.canContribute && <TaskAltIcon color="success" />}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

type tableTranslationKeys =
    | "features"
    | "explore1"
    | "explore2"
    | "explore3"
    | "create1"
    | "create2"
    | "create3"
    | "contribute1"
    | "contribute2"
    | "contribute3"
    | "feature1"
    | "feature2"
    | "feature3"
    | "feature4"
    | "feature5"
    | "feature6"
    | "feature7"
    | "feature8"
    | "feature9"
    | "feature10"
    | "feature11"
    | "feature12"
    | "feature13"
    | "feature14"
    | "feature15"
    | "feature16"
    | "feature17";

export const { i18n } = declareComponentKeys<tableTranslationKeys>()({
    BetaCapabilitiesTable,
});
const useStyles = tss.create(({ theme }) => ({
    "root": {
        "marginTop": theme.spacing(5),
        "marginLeft": theme.spacing(7),
        "marginRight": theme.spacing(7) + theme.spacing(3),
    },
    "table": {
        "borderRadius": 8,
        "boxShadow": theme.shadows[1],
        "backgroundColor": theme.colors.useCases.surfaces.surface1,
        "&:hover": {
            "boxShadow": theme.shadows[6],
        },
    },
    "title": {
        "fontSize": 20,
        "fontWeight": "bold",
    },
    "cell": {
        "fontSize": 16,
    },
}));
