import { memo } from "react";
import { Text, tss } from "theme";
import { routes } from "router";
import { GlHeader } from "gitlanding/GlHeader";
import { useDomRect } from "powerhooks/useDomRect";
import { declareComponentKeys } from "i18nifty";
import { useLang, useTranslation } from "i18n";
import { LanguageSelect } from "../theme";
import { GlobalStyles } from "tss-react";
import editoHeaderLogoUrl from "assets/illustrations/EDITO-logo-transparent-background-big.png";

export type Props = {
    className?: string;
    isRetracted?: boolean;
};

export const AppHeader = memo((props: Props) => {
    const { className, isRetracted } = props;

    const { t } = useTranslation({ AppHeader });
    const { lang, setLang } = useLang();

    const {
        ref,
        domRect: { height: headerHeight },
    } = useDomRect();

    const { classes, theme } = useStyles({
        "isRetracted": isRetracted ?? false,
        headerHeight,
    });

    return (
        <div className={classes.root} ref={ref}>
            <GlHeader
                className={className}
                title={
                    <a className={classes.titleWrapper} {...routes.home().link}>
                        <img
                            key={4}
                            height={30}
                            src={editoHeaderLogoUrl}
                            alt="EDITO Logo"
                        />
                        <div key={1} style={{ "width": theme.spacing(3) }} />
                        <Text
                            key={2}
                            typo="section heading"
                            className={classes.editoText}
                        >
                            EDITO
                        </Text>
                        <div key={1} style={{ "width": theme.spacing(3) }} />
                        <Text
                            key={0}
                            typo="section heading"
                            className={classes.communityText}
                            color="focus"
                        >
                            {t("platform")}
                        </Text>
                    </a>
                }
                links={[
                    {
                        "label": t("home"),
                        ...routes.home().link,
                    },
                    {
                        "label": t("tutorials"),
                        ...routes.documentation().link,
                    },
                    {
                        "label": t("datalab"),
                        "href": "https://datalab.dive.edito.eu",
                    },
                    {
                        "label": t("exploreViewer"),
                        "href": "https://my-ocean.dive.edito.eu",
                    },
                    {
                        "label": t("viewer"),
                        "href": "https://viewer.dive.edito.eu",
                    },
                    {
                        "label": t("about"),
                        "href": "https://edito.eu",
                    },
                    {
                        "label": t("documentation"),
                        "href": "https://pub.pages.mercator-ocean.fr/edito-infra/edito-tutorials-content/#/",
                    },
                    {
                        "label": t("support"),
                        "href": "mailto:edito-infra-dev@mercator-ocean.eu",
                    },
                ]}
                customItemEnd={{
                    "behaviorOnSmallDevice": "normal",
                    "item": (
                        <>
                            <GlobalStyles
                                styles={{
                                    "#language-menu": {
                                        "zIndex": 4001,
                                    },
                                }}
                            />
                            <LanguageSelect
                                language={lang}
                                onLanguageChange={setLang}
                                variant="big"
                            />
                        </>
                    ),
                }}
                enableDarkModeSwitch={true}
            />
        </div>
    );
});

const useStyles = tss
    .withParams<{ isRetracted: boolean; headerHeight: number }>()
    .create(({ isRetracted, headerHeight }) => ({
        "root": {
            "transition": "margin-top 250ms",
            "margin-top": isRetracted ? -headerHeight : 0,
        },

        "titleWrapper": {
            "display": "flex",
            "justifyContent": "center",
            "alignItems": "center",
            "textDecoration": "none",
        },
        "editoText": {
            "fontWeight": 500,
        },
        "communityText": {
            "fontWeight": 600,
        },
    }));

export const { i18n } = declareComponentKeys<
    | "tutorials"
    | "datalab"
    | "home"
    | "viewer"
    | "exploreViewer"
    | "platform"
    | "about"
    | "documentation"
    | "support"
>()({ AppHeader });
